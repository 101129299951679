import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const prettifySummary = excerpt => {
  const maxLength = 50
  let str = excerpt.substring(0, maxLength)
  if (excerpt.length > maxLength) {
    str += "..."
  }
  return str
}

const Blog = ({ data: { allMdx } }) => {
  return (
    <Layout>
      <Seo title="Blog" />
      <main>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">ブログ</h1>
                <p className="lead fw-normal text-muted mb-4">
                  弊社で取り扱っている製品の最新情報と技術情報をお届けします。
                </p>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container px-5">
            <div className="row gx-5">
              {allMdx.edges.map((mdx, index) => (
                <div className="col-lg-4 mb-5">
                  <div className="card h-100 shadow border-0">
                    <Link to={`${mdx.node.slug}`}>
                      <GatsbyImage
                        image={
                          mdx.node.frontmatter.image.childImageSharp
                            .gatsbyImageData
                        }
                        style={{ height: "200px" }}
                        alt=""
                        className="card-img-top"
                      />
                    </Link>
                    <div className="card-body p-4">
                      <div className="h5 card-title mb-3">
                        {" "}
                        {mdx.node.frontmatter.title}
                      </div>
                      <p className="card-text mb-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: prettifySummary(
                              mdx.node.frontmatter.excerpt
                            ),
                          }}
                        />
                      </p>
                    </div>
                    <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                      <div className="d-flex align-items-end justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="small">
                            <div className="fw-bold">
                              {mdx.node.frontmatter.category}
                            </div>
                            <div className="">
                              <p className="text-gray-600">
                                UPDATED: {mdx.node.frontmatter.date}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="align-self-start">
                          <Link to={`${mdx.node.slug}`}>
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                            >
                              詳細
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  {
    allMdx(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          frontmatter {
            category
            title
            date
            excerpt
            image {
              childImageSharp {
                gatsbyImageData(
                  formats: AUTO
                  placeholder: BLURRED
                  quality: 100
                  height: 200
                )
              }
            }
          }
          slug
        }
      }
    }
  }
`
